[data-tooltip]:hover::before {
  position: absolute;
  content: attr(data-tooltip);
  z-index: 1;
  transform: translateX(-35%);
  top: -40px;
  opacity: 0.95;
  border-radius: 6px;
  background-color: var(--ion-color-light-contrast);
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, .12);
  color: var(--ccs-base-white);
  font-size: 13px;
  line-height: 20px;
  padding: 10px 30px;
}
