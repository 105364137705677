@font-face {
  font-family: "WorkSans";
  font-style: normal;
  src: url(/assets/fonts/worksans/WorkSans-Regular.ttf);
}

@font-face {
  font-family: "WorkSans-Regular";
  font-style: normal;
  src: url(/assets/fonts/worksans/WorkSans-Regular.ttf);
}

@font-face {
  font-family: "WorkSans-Bold";
  font-style: normal;
  src: url(/assets/fonts/worksans/WorkSans-Bold.ttf);
}

@font-face {
  font-family: "WorkSans-SemiBold";
  font-style: normal;
  src: url(/assets/fonts/worksans/WorkSans-SemiBold.ttf);
}

@font-face {
  font-family: "WorkSans-Medium";
  font-style: normal;
  src: url(/assets/fonts/worksans/WorkSans-Medium.ttf);
}

@font-face {
  font-family: "WorkSans-ExtraBold";
  font-style: normal;
  src: url(/assets/fonts/worksans/WorkSans-ExtraBold.ttf);
}

@font-face {
  font-family: "NotoColorEmoji-Regular";
  font-style: normal;
  src: url(/assets/fonts/notocoloremoji/NotoColorEmoji-Regular.ttf);
}

@font-face {
  font-family: "SourceSansPro";
  font-style: normal;
  src: url(/assets/fonts/sourcesans/SourceSansPro-Regular.ttf);
}

@font-face {
  font-family: "SourceSansPro-Regular";
  font-style: normal;
  src: url(/assets/fonts/sourcesans/SourceSansPro-Regular.ttf);
}

@font-face {
  font-family: "SourceSansPro-Bold";
  font-style: normal;
  src: url(/assets/fonts/sourcesans/SourceSansPro-Bold.ttf);
}

@font-face {
  font-family: "SourceSansPro-SemiBold";
  font-style: normal;
  src: url(/assets/fonts/sourcesans/SourceSansPro-SemiBold.ttf);
}

@font-face {
  font-family: "SourceSansPro-Medium";
  font-style: normal;
  src: url(/assets/fonts/sourcesans/SourceSansPro-Regular.ttf);
}

@font-face {
  font-family: "SourceSansPro-ExtraBold";
  font-style: normal;
  src: url(/assets/fonts/sourcesans/SourceSansPro-Black.ttf);
}
