.linechart-label {
  display: inline-flex;
  margin-right: 20px;
  align-items: center;
}

.no-data {
  opacity: 0.25;
  font-family: var(--ccs-font-bold);
  font-size: 26px;
  color: var(--ccs-supporting-dark-grey);
  letter-spacing: -0.35px;
}
