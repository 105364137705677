.custom-error {
  flex-wrap: wrap;

  ng-component {
    width: 100%;
    @include media("screen", ">tablet") {
      padding-left: 22%;
    }

    p {
      color: var(--ccs-risk-red);
    }
  }

  &:not(.disabled) {
    label {
      &:not(.error) {
        font-family: var(--ccs-font-semibold);
        color: var(--ccs-risk-red);
      }

      &:first-child {
        font-family: var(--ccs-font-semibold);
        color: var(--ccs-risk-red);
      }

      &.error {
        margin: 10px 0 0;
        float: none;
        width: 100%;
        padding-left: 22%;
      }
    }
  }

  .ui-select.select-type2 {
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    align-self: flex-start;

    label {
      &.error {
        padding-left: 0 !important;
        order: 2;
      }
    }
  }
}

.toast-error {
  background: #fbe4e8;
  text-align: center;
  font-size: 15px;
  line-height: 1.33;
  color: var(--ccs-risk-red);
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 9;
  width: 100%;
  height: 0;
  transition: all .5s ease-in-out;
  transform: translateY(-75px);
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  @include media("screen", "<phone") {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  &.in {
    height: 75px;
    transform: translateY(0);
    transition: all .5s ease-in-out;
  }

  strong {
    font-weight: normal;
    font-family: var(--ccs-font-semibold);
    margin-right: 10px;
  }
}

@include on-ie-11 {
  .toast-error {
    &.in {
      height: 75px;
      transform: translateY(0);
      transition: all .5s ease-in-out;
    }
  }
}
