.folder-name {
  color: var(--ccs-color-field-text);
  font-family: var(--ccs-font-semibold);
  font-size: 16px;
  line-height: 23px;
  padding: 6px 10px;
  border: 1px solid var(--ccs-color-field-border);
  border-radius: 4px;
  background-color: var(--ccs-color-field);
  display: inline-flex;
  align-items: center;
  white-space: nowrap;

  &:before {
    content: "";
    background: url(/assets/icons/folder2.svg) no-repeat center;
    width: 18px;
    height: 18px;
    background-size: 27px;
    margin-right: 10px;
  }
}
