.loading-state {
  text-align: center;
}

.custom-loading {
  width: 100%;
  height: 100%;

  .loading-wrapper {
    box-shadow: none !important;
    background: transparent !important;
    max-width: 100%;

    .loading-content {
      font-size: 16px;
    }

    &:before {
      content: "";
      background: url('/assets/images/ajax-loader.gif');
      position: absolute;
      top: -25px;
      width: 46px;
      height: 46px;
      border-radius: 46px;
      opacity: .18;
    }
  }
}
