.custom-select {
  margin: 0 15px;
  min-width: 192px;

  .ng-select-container {
    height: 43px !important;
    position: unset !important;
    background: var(--ccs-base-white);
    border: 1px solid var(--ccs-supporting-light-grey) !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .25) !important;
    border-radius: var(--border-radius);

    .ng-value-container {
      padding: 0 20px;
      font-size: 15px;
      color: var(--ccs-supporting-dark-grey);
    }

    .ng-arrow-wrapper {
      width: 10px;
      margin-right: 20px;

      .ng-arrow {
        border-color: var(--ccs-supporting-medium-grey2) transparent transparent;
      }
    }
  }

  &.ng-select-opened {
    .ng-arrow {
      border-color: transparent transparent var(--ccs-base-blue) !important;
    }
  }

  .ng-dropdown-panel {
    opacity: 1;
    border-top: none;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .25);
    border-color: var(--ccs-supporting-light-grey);

    .ng-option {
      padding: 12px 20px;
      font-size: 15px;
      line-height: 16px;

      &.ng-option-selected {
        background: var(--ccs-supporting-light-grey3) !important;
        color: var(--ccs-supporting-medium-grey3);

        span {
          font-weight: inherit !important;
        }

        &.ng-option-marked {
          color: var(--ccs-base-blue);
          font-family: var(--ccs-font-semibold);
        }
      }

      &.ng-option-marked {
        background: var(--ccs-base-blue);
        color: var(--ccs-base-white);
      }
    }
  }
}
