.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  min-width: 200px;
  font-family: var(--ccs-font-regular);

  *:focus {
    outline: 0px;
  }

  .select2-dropdown {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: var(--border-radius);
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    z-index: 1051;
  }

  .select2-selection__rendered {
    border-color: var(--ccs-supporting-light-grey) !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .25) !important;
    border-radius: var(--border-radius);
    min-height: 43px;

    &:after {
      display: block;
      content: "";
      position: absolute;
      right: 8px;
      top: 19px;
      border-color: var(--ccs-supporting-medium-grey2) transparent transparent transparent;
      border-style: solid;
      border-width: 5px 5px 0 5px;
    }
  }

  .select2-results {
    display: block;
    padding: 0;
    margin: 4px 0px 0px 0;

    .select2-highlighted {
      background: var(--ccs-base-blue);
      color: var(--ccs-base-white);
      border-radius: 0px;
    }
  }

  .select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .select2-results__option {
    min-height: 42px;
    user-select: none;
    -webkit-user-select: none;
    padding: 13px 8px;
    font-size: 15px;
    font-family: var(--ccs-font-regular);
    line-height: 16px;

    &[aria-selected] {
      cursor: pointer;
    }
  }

  .select2-results__option--highlighted[aria-selected=false] {
    background: var(--ccs-base-blue);
    color: var(--ccs-base-white);
  }

  .select2-results__option[aria-disabled=true] {
    color: var(--ccs-supporting-medium-grey);
    background: var(--ccs-supporting-light-grey);
    opacity: 0.9;
  }

  .select2-results__option[aria-selected=true] {
    color: var(--ccs-supporting-medium-grey);
    background: var(--ccs-supporting-light-grey);
    font-family: var(--ccs-font-semibold);
  }

  .select2-results__option--highlighted[aria-selected=true] {
    color: var(--ccs-base-blue);
    background: var(--ccs-supporting-light-grey);
  }

  .select2-results__options[aria-multiselectable=true] .select2-results > .select2-results__options > .select2-results__option {
    padding-left: 8px;
    padding-right: 8px;
  }

  .select2-results__options[aria-multiselectable=true] .select2-results__option[aria-selected=true] {
    display: flex;
  }

  .select2-results__options[aria-multiselectable=true] .select2-results__option[aria-selected=true]:before {
    content: '×';
    display: block;
    cursor: pointer;
    font-size: 20px;
    margin: -1px 5px 0 0;
    font-weight: 500;
  }

  .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: auto;
    user-select: none;
    -webkit-user-select: none;
    border-radius: var(--border-radius);
    background-color: var(--ccs-base-white);
  }

  .select2-search--dropdown {
    display: block;
    padding: 4px;
  }

  &.select2-container--open .select2-dropdown {
    left: 0;
  }

  &.select2-container--open .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -2px;
    margin-bottom: 20px;
    border-color: var(--ccs-supporting-light-grey);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .25);
    background: var(--ccs-base-white);
    max-height: 40vh;
    overflow-x: auto;
  }

  &.select2-container--open .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: var(--ccs-supporting-light-grey);
    box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, .25);
    background: var(--ccs-base-white);
    max-height: 40vh;
    overflow-x: auto;
  }

  &.select2-container--open .select2-selection--single .select2-selection__rendered {
    color: var(--ccs-base-blue);
  }

  .select2-search {
    margin-top: 0px;
    max-width: 100%;

    input,
    .select2-search__field {
      height: 22px;
      font-size: 15px;
      width: 100%;
      max-width: 100%;
      appearance: none;
      -webkit-appearance: none;
    }

    input::placeholder,
    .select2-search__field::placeholder {
      text-align: left;
      color: var(--ccs-supporting-dark-grey);
      font-family: var(--ccs-font-regular);
    }
  }

  .select2-search--dropdown .select2-search__field {
    padding: 4px;
    box-sizing: border-box;
    border: 1px solid var(--ccs-color-border);
    border-radius: var(--border-radius);
    outline: none;
    height: 43px;
  }

  .select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  .select2-search--dropdown.select2-search--hide {
    display: none;
  }

  .select2-close-mask {
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 99;
    background-color: var(--ccs-base-white);
    filter: alpha(opacity=0);
  }

  .select2-search--inline {
    float: left;

    .select2-search__field {
      width: 100% !important;
      box-sizing: border-box;
      border: none;
      font-size: 100%;
      padding: 0;
      margin-top: 0px;
      height: 43px;
    }

    .select2-search__field::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  .select2-choice {
    border: 1px solid var(--ccs-supporting-light-grey2);
    height: 40px;
    border-radius: 0px;
    font-family: var(--ccs-font-regular);
    font-size: 15px !important;
    text-indent: 1px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-image: none;

    div {
      border-left: 1px solid var(--ccs-supporting-light-grey2);
      -webkit-border-radius: 0 4px 4px 0;
      -moz-border-radius: 0 4px 4px 0;
      border-radius: 0 4px 4px 0;
      -webkit-background-clip: padding-box;
      -moz-background-clip: padding;
      background-clip: padding-box;
    }

    .select2-arrow {
      border: 0px;
      border-radius: 0px;
      background: transparent;
      background-image: none;
    }
  }

  &.select2-drop-above .select2-choice {
    border-bottom-color: var(--ccs-supporting-light-grey2);
    border-radius: 0px;
  }

  .select2-drop {
    margin-top: -2px;
    border: 1px solid var(--ccs-supporting-light-grey);
    border-top: 0;
    border-radius: 0px !important;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    &.select2-drop-above {
      margin-top: 2px;
      border-top: 1px solid var(--ccs-supporting-light-grey);
      border-bottom: 0;
      -webkit-border-radius: 4px 4px 0 0;
      -moz-border-radius: 4px 4px 0 0;
      border-radius: 4px 4px 0 0;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

  &.select2-container-active .select2-choice,
  &.select2-container-active .select2-choices {
    border: 1px solid #979797;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .select2-dropdown-open .select2-choice {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-border-bottom-left-radius: 0;
    -moz-border-radius-bottomleft: 0;
    border-bottom-left-radius: 0;
    -webkit-border-bottom-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    border-bottom-right-radius: 0;

    .select2-choice div {
      background: transparent;
      border-left: none;
      filter: none;
    }
  }

  /****** Single SCSS *******/
  .select2-selection--single {
    background-color: var(--ccs-base-white) !important;
    height: auto;

    .select2-selection__rendered {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background-color: var(--ccs-base-white) !important;
      color: var(--ccs-supporting-dark-grey);
      font-size: 15px !important;
      font-family: var(--ccs-font-regular);
      border: 1px;
      border-color: var(--ccs-color-border);
      border-style: solid;
      line-height: 23px !important;
      padding: 9px 20px 9px 8px;
    }

    .select2-selection__arrow {
      height: 43px;
      position: absolute;
      top: 0;
      right: 1px;
      width: 20px;
    }

    .select2-selection__arrow b {
      display: none;
    }

    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-family: var(--ccs-font-bold);
      position: relative;

      &:hover {
        color: var(--ccs-base-blue);
      }
    }
  }

  &.select2-container-multi .select2-choices {
    height: auto !important;
    height: 1%;
    border: 2px solid var(--ccs-supporting-light-grey2);
  }

  &.select2-container-multi.select2-container-active .select2-choices {
    border: 2px solid var(--ccs-supporting-light-grey2);
    -webkit-border-radius: var(--border-radius);
    -moz-border-radius: var(--border-radius);
    border-radius: var(--border-radius);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none;
    border-radius: var(--border-radius);
    padding-top: 0;
    padding-bottom: 0;
    background: white;
    border: none;

    .select2-selection__clear {
      display: none;
    }

    .select2-selection__choice__remove:hover {
      color: var(--ccs-base-blue);
      cursor: pointer;
      background-color: var(--ccs-supporting-light-grey3);
      border-color: var(--ccs-supporting-border-grey);
    }

    .select2-selection__rendered {
      border: 1px solid var(--ccs-supporting-light-grey) !important;
      border-radius: var(--border-radius);
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background-color: var(--ccs-base-white);
      box-sizing: border-box;
      list-style: none;
      margin: 0 0 -4px 0;
      padding: 0 20px 0 8px;
      width: 100%;
      font-size: 15px;
      font-family: var(--ccs-font-semibold);
    }
  }

  &.select2-container--open .select2-selection__rendered:after {
    display: block;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    border-color: var(--ccs-base-blue) transparent transparent transparent !important;
  }

  .select2-selection--multiple .select2-selection__choice {
    color: var(--ccs-supporting-medium-grey3);
    background-color: var(--ccs-supporting-light-grey3);
    -webkit-border-radius: var(--border-radius);
    border-radius: var(--border-radius);
    border: 1px solid var(--ccs-supporting-border-grey);
    padding: 3px 8px 2px 0;
    cursor: default;
    float: left;
    margin: 7px 5px 0 0;
    white-space: normal;
    display: flex;
  }

  .select2-selection--multiple .select2-selection__choice__remove {
    color: var(--ccs-supporting-medium-grey3);
    margin-top: -3px;
    font-size: 20px;
    margin-right: 6px;
    margin-left: 6px;
    float: left;
    background-color: var(--ccs-supporting-light-grey3);
    border-color: var(--ccs-supporting-border-grey);
  }

  .select2-results__option[role="group"] {
    padding: 0;

    .select2-results__group {
      display: block;
      background-color: var(--css-base-menu-dark-grey) !important;
      color: var(--ccs-base-white) !important;
      user-select: none;
      -webkit-user-select: none;
      padding: 13px 8px;
      font-size: 15px;
      font-family: var(--ccs-font-regular);
      line-height: 16px;
      font-weight: normal;
    }
  }
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

// CUSTOM inputs SELECT2 styles
.custom-inputs {
  .select2-container {
    &[aria-multiselectable="true"] {
      position: relative;
      display: flex;
      display: -ms-flexbox;
    }

    .select2-selection--multiple {
      .select2-search--inline {
        .select2-search__field {
          padding-left: 12px;
          height: 43px;
          font-family: var(--ion-font-family) !important;
          -webkit-appearance: none;
        }
      }
    }

    .select2-selection--single {
      border: none;

      .select2-selection__rendered {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.26);
        border: solid 1px var(--ccs-color-border);
        border-radius: var(--border-radius);
        padding-left: 20px !important;
        padding-right: 20px;
        padding-bottom: 0;
      }
    }
  }

  .select2-container .select2-results__option[role="group"] .select2-results__group,
  .select2-container .select2-results__option {
    padding: 13px 20px;
  }

  .select2-container [role='group'].select2-results__option {
    padding: 0;
  }
}
