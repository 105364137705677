.custom-alert {
  --max-width: 400px;

  ion-backdrop {
    opacity: .8 !important;
    --ion-backdrop-color: var(--ccs-base-dark-blue);
  }

  .alert-wrapper {
    padding: 30px 30px;

    .alert-head {
      margin: 0;
      padding: 0;

      h2 {
        font-family: var(--ccs-font-bold);
        font-size: 27px;
        color: var(--ccs-base-dark-blue);
        letter-spacing: 0;
        text-align: center;
        margin-bottom: 15px;
      }
    }

    .alert-message {
      padding: 0;
      font-family: var(--ccs-font-regular);
      font-size: 16px;
      color: var(--ccs-supporting-medium-grey2);
      letter-spacing: 0;
      text-align: center;
      line-height: 24px;
      margin-bottom: 20px;

      .alert-list-view {
        font-size: 14px;
        text-align: left;
        line-height: 22px;
      }
    }

    .alert-button-group {
      padding: 0;
      display: flex;
      justify-content: space-around;
      @include media("screen", "<tablet") {
        flex-wrap: wrap;
      }

      button {
        margin: 0;
        padding: 0;
        font-family: var(--ccs-font-semibold);
        font-size: 15px;
        color: var(--ccs-base-blue);
        text-transform: capitalize;
      }
    }
  }
}
