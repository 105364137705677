.fullscreen-modal {
  &::part(content) {
    width: 100%;
    height: 100%;
  }
}

.media-screen-modal {
  --box-shadow: none !important;
  --width: 100%;
  --height: 100%;
  --backdrop-opacity: 0 !important;

  .modal-wrapper {
    background: transparent;
  }
}

.viewer-backdrop {
  background-color: rgba(var(--ion-color-primary-rgb), .9)
}
