.button-block {
  &.centered {
    text-align: center;
  }

  ion-button {
    margin-bottom: 15px;
    margin-top: 15px;

    &:not(:first-child) {
      margin-left: 15px !important;
    }
  }
}

.page-button {
  border-radius: var(--border-radius) !important;
  --padding-bottom: 10px;
  --padding-end: 1em;
  --padding-start: 1em;
  --padding-top: 10px;
  --box-shadow: none;
  margin: 10px 0;
  text-transform: capitalize !important;
  font-family: var(--ccs-font-bold) !important;
  font-size: 16px !important;
  line-height: 45px;
  min-height: 45px !important;
  height: 45px;
  letter-spacing: normal !important;
  transition: all 0.25s ease;

  &.smaller {
    --padding-end: 0.7em !important;
    --padding-start: 0.7em !important;
    height: 28px !important;
    font-size: 14px !important;
    line-height: 28px !important;
  }

  &.table-header-button {
    margin-top: 0;
    position: absolute;
    z-index: 1;
    margin-bottom: 20px;
  }

  &.button-right {
    position: relative !important;
    float: right !important;
    margin-right: 0 !important;
  }

  &.secondary {
    background: var(--ion-color-secondary);
    padding: 0 1em;
    box-shadow: none;
    color: white;
    outline: none;
    border: 1px solid var(--ccs-color-border);

    &:hover {
      opacity: .9;
    }
  }
}

.button-small-label-white {
  font-family: var(--ccs-font-bold);
  font-size: 14px;
  color: var(--ccs-base-white);
  letter-spacing: 0;
  text-align: center;
}

ion-button {
  --border-radius: var(--border-radius);
  border-radius: var(--border-radius);
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ccs-color-border);
  --box-shadow: none;
  transition: all 0.25s ease;

  .button-native {
    background: var(--ccs-base-blue) !important;
  }
}

.title-block .page-button {
  --padding-end: 20px;
  --padding-start: 20px;
}

@include on-edge {
  ion-button.button-solid.ion-color.ion-color-light .button-native {
    background: var(--ion-color-base) !important;
  }
}

@include on-ie-11 {
  ion-button.button-solid.ion-color.ion-color-light .button-native {
    background: var(--ccs-base-grey) !important;
  }
  .button-native img {
    max-width: 30px;
  }
}

.viewer-button {
  background: url('/assets/images-galery/close.svg') no-repeat center;
  background-size: 85px;
  cursor: pointer;
  height: 75px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0;
  width: 75px;
}

.primary-button {
  padding: 0 25px;
  line-height: 45px;
  height: 45px;
  font-size: 16px;
  border-radius: var(--border-radius);
  border: 1px solid var(--ccs-color-border);
  box-shadow: none;
  min-width: 89px;
  background-color: var(--ccs-base-blue);
  color: var(--ccs-base-white);
  font-family: var(--ccs-font-bold);
  display: block;
  margin: 0 auto;
}

.button-styled {
  &.hidden {
    display: none;
  }
}

.button-link {
  background: transparent;

  &:focus {
    opacity: 0.8 !important;
  }
}

.export-btn, .header-btn {
  background-color: var(--ccs-base-blue);
  color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--ccs-color-border);
  text-align: center;
  padding: 13px 20px;
  line-height: 19px;
  font-size: 16px;
  font-family: var(--ccs-font-bold);
  cursor: pointer;
  display: inline-block;
  width: fit-content;
  margin: 0 15px 25px 0;
  font-family: var(--ccs-font-bold) !important;
}
