table.dataTable {
  font-size: 15px;
}

table.dataTable tbody tr {
  cursor: pointer;
}

table.dataTable tbody th,
table.dataTable tbody td {
  padding: 10px;
}

table.dataTable td p {
  margin: 0;
}

.emptyDataTable {
  .dt-buttons {
    .dt-button {
      display: none;
    }
  }

  .dt-button-collection {
    .dt-button {
      display: block;
    }
  }

  .dataTables_scroll,
  .table-container {
    display: none;
  }
}

.js-not-grouped-data {
  .dataTables_filter {
    display: none;
  }

  .not-group-hidden {
    display: none;
  }
}

.js-dropdown-table {
  margin-bottom: 20px;

  .dataTables_filter {
    display: none;
  }

  .title {
    color: var(--ccs-base-dark-blue);
    font-size: 18px;
    font-family: var(--ccs-font-bold);
    letter-spacing: 0;
    line-height: 21px;
    text-transform: capitalize;
    box-sizing: border-box;
    height: 70px;
    border: 1px solid var(--ccs-supporting-border-grey);
    background-color: var(--ccs-supporting-light-grey3);
    padding: 25px 48px 25px 30px;
    font-family: var(--ccs-font-bold) !important;
    cursor: pointer;

    &::after {
      content: "";
      border: solid var(--ccs-supporting-medium-grey);
      border-width: 0 3px 3px 0;
      padding: 3px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      float: right;
    }

    &:hover {
      background-color: var(--ccs-supporting-blue-highlight);
      color: var(--ccs-base-blue);

      &::after {
        border-color: var(--ccs-base-blue);
      }
    }

    &.opened {
      background-color: var(--ccs-base-dark-blue);
      color: var(--ccs-base-white);

      &::after {
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
        border-color: var(--ccs-base-white);
        margin-top: 4px;
      }

      &:hover {
        background-color: var(--ccs-base-dark-blue);
        color: var(--ccs-base-white);

        &::after {
          border-color: var(--ccs-base-white);
        }
      }
    }
  }

  .dataTables_wrapper {
    display: none;
    border: 1px solid var(--ccs-supporting-border-grey);
    padding: 30px;

    &.opened {
      display: block;
    }
  }

  .closed-show-el {
    display: inline;
    padding: 0 10px;
  }

  .always-show-el {
    //display: inline;
  }

  .open-show-el {
    display: none;
    padding: 0 10px;
  }

  &.opened {
    .title {
      background-color: var(--ccs-base-dark-blue);
      color: var(--ccs-base-white);

      &::after {
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
        border-color: var(--ccs-base-white);
        margin-top: 4px;
      }

      &:hover {
        background-color: var(--ccs-base-dark-blue);
        color: var(--ccs-base-white);

        &::after {
          border-color: var(--ccs-base-white);
        }
      }

      .open-show-el {
        display: inline;
      }

      .closed-show-el {
        display: none;
      }
    }

    .dataTables_wrapper {
      display: block;
    }
  }
}

.zone-table-header {
  font-family: var(--ion-font-family) !important;
  color: var(--ccs-color-form-prompt);
  font-size: 12px !important;
  line-height: 14px !important;
  text-align: left;
  padding-left: 10px !important;
}

.zone-table-header-selected {
  font-family: var(--ion-font-family) !important;
  color: var(--ccs-base-blue);
  font-size: 12px !important;
  line-height: 14px !important;
  text-align: left;
  padding-left: 10px !important;
}

.table-header {
  font-family: var(--ion-font-family) !important;
  color: #999999 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  text-align: left;
  text-transform: uppercase;

  &.sorting {
    color: #999999 !important;
    letter-spacing: 0 !important;
    font-family: var(--ccs-font-medium) !important;
  }
}

.buttons-columnVisibility.active {
  font-family: var(--ccs-font-bold) !important;
  text-transform: uppercase;
  text-overflow: ellipsis;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  line-height: 45px !important;
  height: 45px !important;
  font-size: 16px !important;
  background-color: var(--ccs-base-blue) !important;
  background-image: none !important;
  color: var(--ccs-base-white) !important;
  border-radius: var(--border-radius) !important;
  border: 1px solid var(--ccs-color-border) !important;
  box-shadow: none;
  opacity: 1 !important;
}

.button-styled {
  font-family: var(--ccs-font-bold) !important;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  line-height: 19px !important;
  min-height: 45px !important;
  font-size: 16px !important;
  background-color: var(--ccs-base-blue) !important;
  background-image: none !important;
  border-radius: var(--border-radius) !important;
  border: 1px solid var(--ccs-color-border) !important;
  box-shadow: none;
  color: var(--ccs-base-white) !important;
  opacity: 1 !important;

  &.btn {
    line-height: 26px !important;
    padding: 7px 15px !important;
    font-size: 14px !important;
    margin-right: 10px;
  }

  &.overflow-button {
    position: absolute;
    top: -43px;
    right: 75px;
  }
}

.buttons-columnVisibility:not(.active) {
  color: var(--ccs-base-black) !important;
  background-color: var(--ccs-supporting-light-grey) !important;
  font-family: var(--ccs-font-bold) !important;
  text-transform: uppercase;
  text-overflow: ellipsis;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  line-height: 45px !important;
  height: 45px !important;
  font-size: 16px !important;
  border-radius: var(--border-radius) !important;
  border: 1px solid var(--ccs-color-border) !important;
  background-image: none !important;
  box-shadow: none;
}

table.dataTable {
  tbody {
    tr {
      height: 60px;
      background-color: #fff;

      &:hover {
        background-color: var(--ccs-supporting-blue-highlight) !important;

        td {
          background-color: var(--ccs-supporting-blue-highlight) !important;
        }
      }
    }
  }

  thead {
    span {
      display: inline-block;
      width: 16px;
      height: 17px;
      margin-left: 9px;
      // &.sort-icon {
      //   display: inline-block;
      //   width: 16px;
      //   height: 17px;
      //   margin-left: 9px;
      // }
    }

    .sorting {
      span {
        // background: url('../node_modules/datatables.net-dt/images/sort_both.png') no-repeat;
        position: relative;

        &:after {
          border: 5px solid transparent;
          content: "";
          display: block;
          height: 0;
          right: 5px;
          top: 50%;
          position: absolute;
          width: 0;
          border-top-color: #E5E5E5;
          margin-top: 1px;
        }
      }
    }

    .sorting_asc {
      color: var(--ccs-base-blue) !important;

      span {
        // background: url('../node_modules/datatables.net-dt/images/sort_asc.png') no-repeat;
        position: relative;

        &:before {
          border: 5px solid transparent;
          content: "";
          display: block;
          height: 0;
          right: 5px;
          top: 50%;
          position: absolute;
          width: 0;
          border-bottom-color: var(--ccs-base-blue);
          margin-top: -4px;
        }
      }
    }

    .sorting_desc {
      color: var(--ccs-base-blue) !important;

      span {
        // background: url('../node_modules/datatables.net-dt/images/sort_desc.png') no-repeat;
        position: relative;

        &:after {
          border: 5px solid transparent;
          content: "";
          display: block;
          height: 0;
          right: 5px;
          top: 50%;
          position: absolute;
          width: 0;
          border-top-color: var(--ccs-base-blue);
          margin-top: 1px;
        }
      }
    }

    .sorting_asc_disabled {
      span {
        // background: url('../node_modules/datatables.net-dt/images/sort_asc_disabled.png') no-repeat;
        position: relative;

        &:before {
          border: 5px solid transparent;
          content: "";
          display: block;
          height: 0;
          right: 5px;
          top: 50%;
          position: absolute;
          width: 0;
          border-bottom-color: var(--ccs-color-border);
          margin-top: -4px;
        }
      }
    }

    .sorting_desc_disabled {
      span {
        // background: url('../node_modules/datatables.net-dt/images/sort_desc_disabled.png') no-repeat;
        position: relative;

        &:after {
          border: 5px solid transparent;
          content: "";
          display: block;
          height: 0;
          right: 5px;
          top: 50%;
          position: absolute;
          width: 0;
          border-top-color: var(--ccs-color-border);
          margin-top: 1px;
        }
      }
    }
  }
}

#markedAsFixedTable thead th,
#openTable thead th,
#closedTable thead th,
#behaviorTable thead th,
#thumbsUpTable thead th,
table.dataTable thead th {
  background: transparent !important;
  white-space: nowrap;
}

.nowrap {
  white-space: nowrap !important;
}

.unread-class {
  font-family: var(--ccs-font-semibold);
  font-size: 15px;
  color: var(--ccs-supporting-dark-grey);
  letter-spacing: 0;
  line-height: 22px;
}

.particpants-link {
  font-family: var(--ccs-font-semibold);
  font-size: 12px;
  color: var(--ccs-base-blue);
  letter-spacing: 0;
  margin-top: 3px;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.table-select-all-wrap {
  position: relative;
  display: table;
  margin: 10px auto 5px;

  input.table-select-all[type="checkbox"] {
    position: absolute;
    width: 20px;
    height: 20px;
    appearance: none;
    outline: none;
    z-index: 1;

    &::-ms-check {
      opacity: 0;
    }

    &:focus {
      outline: none;
    }

    + label {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-left: -1px;
    }

    + label:before {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      background: var(--ccs-base-white);
      border: 1px solid var(--ccs-color-border);
      border-radius: var(--border-radius-checkbox);
    }

    &:checked + label:before {
      border-color: var(--ccs-base-blue);
      box-shadow: inset 0 0 0 1px var(--ccs-base-blue);
    }

    &:checked + label:after {
      content: '' !important;
      background: url('/assets/icons/icon-checkmark.svg');
      position: absolute !important;
      width: 13px;
      height: 10px;
      top: 0;
      bottom: 0;
      left: 1px;
      right: 0;
      margin: auto;
    }
  }
}

.dataTables_scroll {
  .dataTables_scrollHead,
  .dataTables_scrollHeadInner,
  .dataTables_scrollHeadInner .dataTable,
  .dataTables_scrollBody,
  .dataTables_scrollBody .dataTable {
    width: 100% !important;
  }

  .dataTables_scrollHead .dataTable {
    @include media("screen",
      ">tablet") {
      table-layout: fixed;
    }
  }
}

.dataTables_scrollBody {
  background-image: none !important;
  background-color: transparent !important;
  border-color: var(--ccs-color-border) !important;

  .dts_label {
    display: none !important;
  }
}

.table-container,
.dt-rowReorder-float {
  .table-header-styled {
    padding-left: 20px;
  }

  tr td:not(:first-child) {
    padding-left: 20px;
  }

  .select-checkbox {
    position: relative;

    &:before {
      display: block;
      background: var(--ccs-base-white);
      border-radius: var(--border-radius-checkbox) !important;
      width: 20px !important;
      height: 20px !important;
      border-color: var(--ccs-color-border) !important;
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      margin: 0 !important;
    }
  }

  .selected .select-checkbox {
    &:after {
      content: '' !important;
      background: url('/assets/icons/icon-checkmark.svg');
      width: 13px;
      height: 10px !important;
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      margin: 0 !important;
    }

    &:before {
      border-color: var(--ccs-base-blue) !important;
      box-shadow: inset 0 0 0 1px var(--ccs-base-blue);
      border-radius: var(--border-radius-checkbox) !important;
    }
  }

  img {
    border-radius: var(--border-radius);
  }
}

@include on-ie-11 {
  table.dataTable tbody td.select-checkbox,
  table.dataTable tbody th.select-checkbox {
    overflow: hidden !important;
  }
  .dt-rowReorder-float .selected .select-checkbox::after,
  .table-container .selected .select-checkbox::after,
  .dt-rowReorder-float .select-checkbox::before,
  .table-container .select-checkbox::before {
    margin: 20px 0 0 0 !important;
    overflow: hidden !important;
  }
}

tbody {
  tr {
    &.odd {
      background-color: var(--ccs-supporting-light-grey3);

      .sorting_1 {
        background-color: var(--ccs-base-grey);
      }
    }

    &.even {
      background-color: var(--ccs-base-white);

      .sorting_1 {
        background-color: var(--ccs-base-grey);
      }
    }

    &.tr-active-zone {
      &,
      td,
      .sorting_1 {
        background-color: var(--ccs-base-blue) !important;
        color: white !important;
      }
    }
  }
}

.dataTables_wrapper .dataTables_filter input {
  margin-left: 10px;
}

.dataTables_filter {
  position: relative;

  input {
    height: 45px !important;
    width: 250px;
    border: 1px solid var(--ccs-color-border);
    border-radius: var(--border-radius);
    outline: none;
    padding: 10px 10px 10px 40px;
    color: #999999;
    background-image: url('/assets/icons/icon-mag-search-blue.svg');
    background-image: url('/assets/icons/icon-mag-search.svg');
    background-position: 15px 15px;
    background-repeat: no-repeat;
    background-size: 14px;

    &:focus {
      background-image: url('/assets/icons/icon-mag-search-blue.svg');
    }

    @include media("screen",
      "<tablet") {
      margin: 0 !important;
    }

    &::placeholder {
      font-family: var(--ccs-font-regular);
      text-align: left !important;
      font-size: 15px;
      color: #999999;
      letter-spacing: 0;
      line-height: 20px;
    }
  }
}

.table-title {
  margin-top: 30px;
  margin-bottom: 30px;

  &.headline-small {
    line-height: 21px;
    height: 65px;
    vertical-align: middle;
  }
}

.dt-buttons {
  margin-bottom: 20px;
}

.custom-controls {
  display: flex;
  padding-bottom: 20px;

  @include media("screen",
    "<tablet") {
    flex-wrap: wrap;
  }

  .export-btn {
    min-height: 45px;
    height: 49px;
    flex: 0 0;
    white-space: nowrap;
    margin-bottom: 0 !important;
  }

  .dt-buttons {
    float: none;
    margin-bottom: 0;
    display: inline-block;

    .dt-button {
      margin: 0 2.5px !important;
      padding-bottom: 15px !important;
    }

    .link {
      background: transparent !important;
      color: var(--ccs-base-blue) !important;
      border: none !important;
      text-decoration: underline;
      padding: 0 !important;
      box-shadow: none !important;
      margin-left: 12.5px !important;
    }
  }
}

.message-table-data {
  padding-bottom: 30px;

  #messageTable_filter {
    display: none !important;
  }

  .dataTables_wrapper {
    .dataTables_scroll {
      div.dataTables_scrollBody {
        max-height: 600px !important;
      }
    }
  }

  table.dataTable thead th,
  table.dataTable tfoot th {
    font-weight: normal;
    font-family: var(--ccs-font-medium) !important;
    padding: 10px 20px;
  }

  table.dataTable tbody th,
  table.dataTable tbody td {
    padding: 10px 20px;
    word-break: break-word;
    font-size: 15px;
    color: var(--ccs-supporting-dark-grey);
  }

  table.dataTable tbody td {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        white-space: nowrap;
        position: relative;
        padding-left: 23px;

        & + li {
          margin-top: 4px;
        }

        &::before {
          content: "";
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background-color: var(--ccs-supporting-dark-grey);
          position: absolute;
          top: 6px;
          left: 0;
        }
      }
    }
  }

  table.dataTable tbody tr {
    cursor: pointer;
  }
}

.dt-rowReorder-moving {
  outline: 1px solid var(--ccs-supporting-medium-grey) !important;
  outline-offset: -1.5px !important;

  .select-checkbox {
    border-top: none !important;
  }
}

table {
  &.dt-rowReorder-float {
    tbody {
      tr {
        &:hover {
          td {
            background-color: transparent !important;
          }
        }
      }
    }
  }
}

.text-message-wrap {
  white-space: normal;
}

@include media("screen",
  "<tablet") {
  div.dt-buttons {
    text-align: left !important;
  }
}

table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
  background-color: var(--ccs-base-white);
}

table.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd {
  background-color: var(--ccs-table-odd);
}

table.dataTable.display tbody > tr.even.selected > .sorting_1,
table.dataTable.order-column.stripe tbody > tr.even.selected > .sorting_1,
table.dataTable.display tbody > tr.odd.selected > .sorting_1,
table.dataTable.order-column.stripe tbody > tr.odd.selected > .sorting_1,
table.dataTable.display tbody tr.odd > .sorting_1,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_1,
table.dataTable.display tbody tr.even > .sorting_1,
table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
  background-color: rgba(#C9C9C9, 0.2);
}

table.dataTable thead th,
table.dataTable thead td {
  border-color: transparent;
}

table.dataTable.row-border tbody tr:first-child th,
table.dataTable.row-border tbody tr:first-child td,
table.dataTable.display tbody tr:first-child th,
table.dataTable.display tbody tr:first-child td {
  border-top: 1px var(--ccs-table-border) solid;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
  border-color: var(--ccs-table-border) !important;
}

table.dataTable td {
  color: var(--ccs-table-text);
}

table.dataTable.stripe tbody > tr.odd.selected,
table.dataTable.stripe tbody > tr.odd > .selected,
table.dataTable.display tbody > tr.odd.selected,
table.dataTable.display tbody > tr.odd > .selected {
  background-color: rgba(#EFEFEF, 0.5);
}

table.dataTable.no-footer {
  border-bottom-color: var(--ccs-table-border);
}

div.dt-button-collection.three-column {
  padding-bottom: 4px;
  width: auto;
}

.dt-button-collection {
  &.custom-col-vis {
    width: auto !important;
    max-width: 70%;

    &.two-column {
      max-width: 35%;
    }
  }
}

div.dt-button-collection button.dt-button:active:not(.disabled),
div.dt-button-collection button.dt-button.active:not(.disabled),
div.dt-button-collection div.dt-button:active:not(.disabled),
div.dt-button-collection div.dt-button.active:not(.disabled),
div.dt-button-collection a.dt-button:active:not(.disabled),
div.dt-button-collection a.dt-button.active:not(.disabled) {
  box-shadow: none;
  text-align: center;
  transition: all 0.25s ease;
}

button.dt-button:active:not(.disabled):hover:not(.disabled),
button.dt-button.active:not(.disabled):hover:not(.disabled),
div.dt-button:active:not(.disabled):hover:not(.disabled),
div.dt-button.active:not(.disabled):hover:not(.disabled),
a.dt-button:active:not(.disabled):hover:not(.disabled),
a.dt-button.active:not(.disabled):hover:not(.disabled) {
  box-shadow: none;
  opacity: .9 !important;
}

div.dt-button-background {
  background: rgba(21, 59, 79, .8);
}

table.dataTable td .page-button {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

@include on-ie-11 {
  .js-dropdown-table table.dataTable {
    margin: 0;
  }
  .js-dropdown-table {
    table.dataTable,
    table.dataTable th,
    table.dataTable td {
      max-width: calc(100% - 18px) !important;
      max-height: calc(100% - 18px) !important;
    }
  }
}

.dataTables_wrapper {
  clear: unset;
}

#exploreTable_wrapper, #responsesTable_wrapper {
  margin-top: 0;
}

#reportTable_customControls {
  margin-top: 20px;
}

// .dt-buttons-parent-element div .dt-buttons{
//   margin-left: -10px;
// }
// .header-button-margin  div span:nth-of-type(1){
//   margin: 0 7.5px 0 -10px !important;
// }

.dataTables_scrollHead {
  .select-all {
    &.select-checkbox {
      &:before {
        content: '';
        margin-top: -6px;
        margin-left: -6px;
        border: 1px solid;
        border-radius: 3px;
        box-sizing: border-box;
      }

      &.selected {
        &:after {
          content: '';
          background: url('/assets/icons/icon-checkmark.svg');
          width: 13px;
          height: 10px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          margin: 0;
        }

        &:before {
          border-color: var(--ccs-base-blue) !important;
          box-shadow: inset 0 0 0 1px var(--ccs-base-blue) !important;
        }
      }
    }
  }
}

.dt-print-view {
  display: block !important;
}
