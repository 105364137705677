ion-app {
  &.hop {
    * {
      --ccs-toolbar-background: #414141;
      --ccs-menu-background: #343434;
      --ccs-menu-child-background: #232323;
      --ion-color-primary-rgb: 65, 65, 65;
    }
  }
}
