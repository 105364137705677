@import "./core";

// Mobiles and tablets
@include media("screen", "<tablet") {
  ion-toolbar .toolbar-content .logo {
    height: 21px !important;
    width: 78px !important;
    left: 15px !important;
    top: 26px !important;
  }
  ion-toolbar .toolbar-content .info-panel {
    margin: 0 0 0 115px;
    position: relative !important;
    right: auto !important;
  }
  ion-toolbar .toolbar-content .info-panel .user-info {
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;
  }
  ion-toolbar .toolbar-content .info-panel .user-info > * {
    padding: 0 10px;
  }
  ion-toolbar .toolbar-content .info-panel .user-info .user-name:after,
  ion-toolbar .toolbar-content .info-panel .user-info .user-team:after {
    display: none !important;
  }
  ion-toolbar .toolbar-content .info-panel .user-info .user-name .message-block {
    display: flex;
  }
  ion-toolbar .toolbar-content .info-panel .user-info .user-name .message-block .count-icon {
    width: 15px !important;
    height: 15px !important;
    background-size: 15px !important;
  }
  .header-wrapper {
    flex-wrap: wrap;
  }
  .header-wrapper {
    button.headline-large {
      text-align: left !important;
      padding: 0 !important;
    }

    > div {
      margin-bottom: 20px;
    }

    .data-tables-filter-block {
      flex: 0 !important;
      padding: 0 !important;
    }

    .header-filters {
      margin-left: 0 !important;
    }
  }
  .tabs {
    flex-wrap: wrap;
  }
  .headline-large,
  .page-title {
    font-size: 24px !important;
  }
  div.dt-button-collection.three-column {
    position: fixed;
    top: 120px;
    left: 20px;
    width: calc(100% - 40px);
  }
  .button-block ion-button {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .button-block ion-button:not(:first-child) {
    margin-left: 10px !important;
  }
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter {
    text-align: left;
  }
  .linechart-label {
    margin-top: 0 !important;
    margin-bottom: 15px !important;
  }
}

// Mobiles only
@include media("screen", "<phone") {
  .filter-container-wrapper {
    padding: 10px !important;

    .filter-container {
      width: 100% !important;
      float: none !important;
      margin: 0 0 20px 0;

      > * {
        width: 100%;
      }
    }

    .select2-container {
      min-width: 170px;
      width: auto !important;
    }

    .filter-buttons {
      flex-wrap: wrap;
      justify-content: flex-start !important;

      .filter-button {
        margin-bottom: 10px;
        float: none !important;
      }
    }
  }
  ion-toolbar .toolbar-content .info-panel .user-info {
    padding: 5px 0;
  }
  ion-toolbar .toolbar-content .info-panel .user-info > * {
    width: 100% !important;
  }
  ion-toolbar .toolbar-content .info-panel .menu {
    width: 100px !important;
    min-width: 100px !important;
  }
  ion-toolbar .toolbar-content .info-panel .user-info .user-name,
  ion-toolbar .toolbar-content .info-panel .user-info .logout-action,
  ion-toolbar .toolbar-content .info-panel .user-info {
    font-size: 11px !important;
  }
  div.dt-button-collection.three-column .dt-button {
    font-size: 10px !important;
  }
}

.ellipsis {
  @include ellipsis;
}
