// these are for color coding the risk values
.risk-index-large {
  font-family: var(--ccs-font-bold);
  font-size: 80px;
  letter-spacing: 0;
  text-align: center;
}

.danger-safety-index {
  color: var(--ccs-risk-red) !important;
}

.medium-safety-index {
  color: var(--ccs-risk-yellow) !important;
}

.low-safety-index {
  color: var(--ccs-risk-green) !important;
}
