.dropbtn-arrow,
.dropbtn {
  background-color: transparent;
  border: none;
}

.dropbtn-arrow {
  cursor: pointer;

  &:hover {
    color: var(--ccs-base-blue) !important;
  }
}

.dropdown {
  font-family: var(--ccs-font-semibold);
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1111 !important;
  top: -15px;
}

.dropdown-content {
  .sub-menu-item,
  a {
    font-family: var(--ccs-font-semibold);
    text-decoration: none;
    display: block;
    width: 340px;
    @include media("screen", "<tablet") {
      width: 300px;
    }
  }
}

.show {
  display: block;
}

.sub-menu-item {
  @include font-style(var(--ccs-base-white), 14px, 600, 16px, 0);
  background-color: var(--ccs-menu-child-background);
  padding: 16px 31px 18px 20px;
  cursor: pointer;

  &:hover {
    color: var(--ccs-base-blue) !important
  }

  &.active {
    color: var(--ccs-base-orange) !important
  }
}

.dashboard-menu-item {
  @include font-style(var(--ccs-base-white), 16px, 600, 19px, 0);
  background-color: var(--css-base-menu-dark-grey);
  padding: 20px 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media("screen", "<tablet") {
    flex-wrap: wrap;
  }

  &-title {
    display: flex;
  }

  img {
    margin-right: 20px;
  }

  .fa-angle-down {
    float: right;
    font-family: var(--ccs-font-bold);
    font-size: 19px;
  }
}
