// http://ionicframework.com/docs/theming/
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

//
// Lightgallery
//
@import 'lightgallery/scss/lightgallery';
@import 'lightgallery/scss/lg-zoom';
@import "lightgallery/css/lg-video.css";

//
// Core: variables, mixins, breakpoints, fonts
//
@import "./theme/core";
@import "./theme/core/fonts";
//
// CSS Parts
//
@import "./theme/alert";
@import "./theme/arrow";
@import "./theme/buttons";
@import "./theme/charts";
@import "./theme/custom-carousel";
@import "./theme/dropdown-menu";
@import "./theme/errors";
@import "./theme/forms";
@import "./theme/table";
@import "./theme/layout";
@import "./theme/loading";
@import "./theme/menu";
@import "./theme/modal";
@import "./theme/modal-image-gallery";
@import "./theme/popover";
@import "./theme/risk-values";
@import "./theme/segment-button";
@import "./theme/select";
@import "./theme/typography";
@import "./theme/utilities-and-formatters";
@import "./theme/folder";
@import "./theme/tooltip";
//
// Plugins
//
@import "./theme/plugins/datatable-overrides";
@import "./theme/plugins/select2";
//
// Pages
//
@import "./theme/pages/observations-pages";
@import "./theme/pages/notification-rule";
//
// Responsive styles
//
@import "./theme/responsive";
