@mixin arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

@mixin right-arrow-direction {
  transform: rotate(-45deg);
}

@mixin left-arrow-direction {
  transform: rotate(135deg);
}

@mixin up-arrow-direction {
  transform: rotate(-135deg);
}

@mixin down-arrow-direction {
  transform: rotate(45deg);
}

@mixin on-ie-11 {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin on-edge {
  @supports (-ms-ime-align: auto) {
    @content;
  }
}

@mixin hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin font-style($color, $size, $weight: 400, $height: normal, $spacing: normal) {
  font-size: $size !important;
  font-weight: $weight !important;
  line-height: $height !important;
  letter-spacing: $spacing !important;
  color: $color !important;
}

@mixin avatar-big {
  width: var(--avatar-big);
  min-width: var(--avatar-big);
  max-width: var(--avatar-big);
  height: var(--avatar-big);
  min-height: var(--avatar-big);
  max-height: var(--avatar-big);
  border-radius: var(--border-radius);
}

@mixin avatar-small {
  width: var(--avatar-small);
  min-width: var(--avatar-small);
  max-width: var(--avatar-small);
  height: var(--avatar-small);
  min-height: var(--avatar-small);
  max-height: var(--avatar-small);
  border-radius: var(--border-radius);
}

@mixin avatar-medium {
  width: var(--avatar-medium);
  min-width: var(--avatar-medium);
  max-width: var(--avatar-medium);
  height: var(--avatar-medium);
  min-height: var(--avatar-medium);
  max-height: var(--avatar-medium);
  border-radius: var(--border-radius);
}

@mixin ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
