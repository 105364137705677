.view-switcher {
  &, ion-segment {
    display: flex;
  }

  ion-segment-button {
    --indicator-color-checked: transparent;
    --color-checked: inherit;
    --color-hover: inherit;
    --padding-bottom: 7px;
    --padding-end: 7px;
    --padding-start: 7px;
    --padding-top: 7px;
    min-height: 40px;
    min-width: 40px;
    border-radius: 4px 0 0 4px;
    border: 1px solid var(--ccs-supporting-light-grey4);
    background: var(--ccs-supporting-light-grey2);
    max-height: 42px;
    max-width: 41px;

    &.segment-button-checked {
      background: var(--ccs-base-blue);
    }

    &:last-child {
      border-left: 0;
      border-radius: 0 4px 4px 0;
    }

    &:first-child {
      border-right: 0;
    }
  }

  ::ng-deep {
    @include on-ie-11 {
      ion-segment-button {
        button {
          padding: 0;

          img {
            width: 26px;
          }
        }
      }
    }
  }
}
