// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

@mixin css-variables {
  /** Corvex globals **/
  /** Eric's color names from Sketch base style library **/
  --ccs-base-white: #FFFFFF;
  --ccs-base-orange: #F57F20;
  --ccs-base-blue: #1AACE5;
  --ccs-btn-blue: #55ACE5;
  --ccs-verified-blue: #1AACE5;
  --ccs-base-dark-blue: #153B4F;
  --ccs-base-grey: #F1F1F1;
  --ccs-base-black: #000000;
  --ccs-quality-purple: #8800FF;
  --ccs-process-purple: #460085;
  --css-asset-azure: #1AA0A4;
  --ccs-opportunity-color: #33CEB0;
  --css-base-menu-dark-grey: #153843;
  --css-base-menu-light-grey: #081f2a;
  --ccs-supporting-light-grey: #efefef;
  --ccs-supporting-light-grey2: #F2F2F2;
  --ccs-supporting-light-grey3: #F9F9F9;
  --ccs-supporting-light-grey4: #ededed;
  --ccs-supporting-medium-grey: #999999;
  --ccs-supporting-medium-grey2: #666666;
  --ccs-supporting-medium-grey3: #868686;
  --css-bar-border: #E5E5E5;
  --css-dash-border: #D4D4D4;
  --ccs-table-odd: #f7f7f7;
  --ccs-table-border: #EFEFEF;
  --ccs-table-text: #515151;
  --ccs-supporting-dark-grey: #515151;
  --ccs-supporting-blue-highlight: #E8F9FF;
  --ccs-supporting-border-grey: #E7E7E7;
  --ccs-text-grey: #AAAAAA;
  --ccs-risk-green: #279F3E;
  --ccs-risk-green-opacity: rgba(39, 159, 62, 0.15);
  --css-chart-value-green: #35A039;
  --css-chart-value-yellow: #C4A500;
  --ccs-risk-light-green: #B6C213;
  --ccs-risk-yellow: #FAD400;
  --ccs-risk-yellow-opacity: rgba(250, 212, 0, 0.15);
  --ccs-risk-orange: #FF9532;
  --ccs-risk-red: #D0021B;
  --ccs-risk-red-opacity: rgba(208, 2, 27, 0.15);
  --ccs-color-border: #DADADA;
  --ccs-color-field: #F4F4F4;
  --ccs-color-field-border: #E3E3E3;
  --ccs-color-field-text: #959595;
  --ccs-color-default: var(--ccs-base-blue);
  --ccs-color-negative: var(--ccs-risk-red);
  --ccs-color-warning: var(--ccs-risk-yellow);
  --ccs-color-medium: var(--ccs-risk-orange);
  --ccs-color-positive: var(--ccs-risk-green);
  --ccs-color-unknown: var(--ccs-supporting-light-grey);
  --ccs-color-descriptor: var(--ccs-supporting-medium-grey);
  --ccs-color-title: var(--ccs-base-dark-blue);
  --ccs-color-text: var(--ccs-supporting-dark-grey);
  --ccs-color-menu-background: #012735;
  --ccs-color-menu-divider: #1A465D;
  --ccs-color-form-prompt: #4A4A4A;
  --ccs-color-avatar-letter: #0D2839;
  /** Color name abstractions that can be overridden **/
  --ccs-toolbar-background: var(--ccs-base-dark-blue);
  --ccs-menu-background: #102F3F;
  --ccs-menu-child-background: #081F2A;
  /** primary **/
  --ion-color-primary: var(--ccs-color-title);
  --ion-color-primary-rgb: 21, 59, 79;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: rgb(18, 51, 68);
  --ion-color-primary-tint: rgb(45, 115, 153);
  /** secondary **/
  --ion-color-secondary: var(--ccs-color-default);
  --ion-color-secondary-rgb: 26, 172, 229;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: rgb(23, 162, 218);
  --ion-color-secondary-tint: rgb(28, 182, 243);
  /** tertiary **/
  --ion-color-tertiary: #f4a942;
  --ion-color-tertiary-rgb: 244, 169, 66;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #d7953a;
  --ion-color-tertiary-tint: #f5b255;
  /** success **/
  --ion-color-success: #279f3e;
  --ion-color-success-rgb: rgb(39, 159, 62);
  --ion-color-success-contrast: #fff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #09bb4d;
  --ion-color-success-tint: #28e070;
  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;
  /** danger **/
  --ion-color-danger: var(--ccs-color-negative);
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d83636;
  --ion-color-danger-tint: #f65050;
  /** light **/
  --ion-color-light: var(--ccs-base-grey);
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #333333;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d7d7;
  --ion-color-light-tint: #f5f5f5;
  /** medium **/
  --ion-color-medium: var(--ccs-supporting-light-grey);
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  /** dark **/
  --ion-color-dark: #111;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e1e1e;
  --ion-color-dark-tint: #383838;
  /** ccsblue **/
  --ion-color-ccsblue: blue;
  --ion-color-ccsblue-rgb: rgb(0, 0, 255);
  --ion-color-ccsblue-contrast: #fff;
  --ion-color-ccsblue-contrast-rgb: 255, 255, 255;
  /** font-family **/
  $font-family-md-base: 'WorkSans-Regular', 'SourceSansPro-Regular', 'NotoColorEmoji-Regular';
  $font-family-ios-base: 'WorkSans-Regular', 'SourceSansPro-Regular', 'NotoColorEmoji-Regular';
  $font-family-wp-base: 'WorkSans-Regular', 'SourceSansPro-Regular', 'NotoColorEmoji-Regular';
  --ion-font-family: 'WorkSans-Regular', 'SourceSansPro-Regular', 'NotoColorEmoji-Regular';
  --ion-default-font: 'WorkSans-Regular', 'SourceSansPro-Regular', 'NotoColorEmoji-Regular';
  --ccs-font-extrabold: WorkSans-ExtraBold, SourceSansPro-ExtraBold, NotoColorEmoji-Regular;
  --ccs-font-bold: WorkSans-Bold, SourceSansPro-Bold, NotoColorEmoji-Regular;
  --ccs-font-semibold: WorkSans-SemiBold, SourceSansPro-SemiBold, NotoColorEmoji-Regular;
  --ccs-font-medium: WorkSans-Medium, SourceSansPro-Medium, NotoColorEmoji-Regular;
  --ccs-font-regular: WorkSans-Regular, SourceSansPro-Regular, NotoColorEmoji-Regular;
  /** navbar variables **/
  --nav-height: 75px;
  --page-width: 1200px;
  /** table variables **/
  --page-padding: 40px;
  --footer-height: 75px;
  /** content variables **/
  --content-padding: 20px;
  --input-padding: 13px 20px;
  /** images variables **/
  --avatar-big: 94px; //4px is for border
  --avatar-small: 40px;
  --avatar-medium: 50px;
  /** border-radius variables **/
  --border-radius: 4px;
  --border-radius-checkbox: 6px;
  /** border variables **/
  --base-border: 1px solid var(--ccs-color-border);
}

:root {
  @include css-variables;
}

// workaround for IE11
* {
  @include css-variables;
}
