.headline-large,
.page-title {
  font-family: var(--ccs-font-bold);
  font-size: 36px;
  color: var(--ccs-base-dark-blue);
  letter-spacing: 0;
}

.page-title.secondary-title {
  font-size: 24px;
  margin-bottom: 0;
  padding-top: 20px;
}

.headline-medium {
  font-family: var(--ccs-font-bold);
  font-size: 24px;
  color: var(--ccs-base-dark-blue);
  letter-spacing: 0;
}

.headline-small {
  font-family: var(--ccs-font-bold);
  font-size: 18px;
  color: var(--ccs-base-dark-blue);
  letter-spacing: 0;
}

.headline-link {
  font-family: var(--ccs-font-bold);
  color: var(--ccs-base-blue);
  letter-spacing: 0;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.body-link {
  font-family: var(--ccs-font-bold);
  font-size: 15px;
  color: var(--ccs-base-blue);
  letter-spacing: 0;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.body-copy {
  font-family: var(--ccs-font-regular);
  font-size: 15px;
  color: var(--ccs-supporting-dark-grey);
  letter-spacing: 0;
  line-height: 20px;
}

.body-copy-bold {
  font-family: var(--ccs-font-semibold);
  font-size: 15px;
  color: var(--ccs-color-form-prompt);
}

.inline-text {
  font-family: WorkSans-SemiBold;
  font-size: 12px;
  color: var(--ccs-supporting-border-grey);
}

.column-header {
  font-family: WorkSans-Medium;
  font-size: 12px;
  color: var(--ccs-supporting-medium-grey);
  letter-spacing: 0;
}

.table-body {
  font-family: WorkSans-Regular;
  font-size: 15px;
  color: var(--ccs-table-text);
  letter-spacing: 0;
}

.table-large {
  font-family: WorkSans-Bold;
  font-size: 18px;
  color: var(--ccs-table-text);
  letter-spacing: 0;
}

.risk-index-red-small {
  font-family: WorkSans-Bold;
  font-size: 18px;
  color: var(--ccs-risk-red);
  letter-spacing: 0;
}


.table-body-bold {
  font-family: WorkSans-SemiBold;
  font-size: 15px;
  color: var(--ccs-table-text);
  letter-spacing: 0;
}

.dash-number-large-link {
  font-family: var(--ccs-font-bold);
  font-size: 110px;
  color: var(--ccs-base-blue);
  letter-spacing: 0;
  text-align: center;
  line-height: 110px;
}

.dash-number-small {
  font-family: var(--ccs-font-bold);
  font-size: 40px;
  color: var(--ccs-base-dark-blue);
  letter-spacing: 0;
  text-align: center;
}

.dash-number-medium {
  font-family: var(--ccs-font-bold);
  font-size: 80px;
  color: var(--ccs-base-dark-blue);
  letter-spacing: 0;
  text-align: center;
}

.detail {
  font-family: var(--ccs-font-regular);
  font-size: 14px;
  color: var(--ccs-supporting-dark-grey);
  letter-spacing: 0;
}

.caption {
  font-family: var(--ccs-font-regular);
  font-size: 13px;
  color: var(--ccs-supporting-medium-grey3);
  letter-spacing: 0;
}

.descriptor {
  font-family: var(--ccs-font-medium);
  font-size: 12px;
  color: var(--ccs-color-form-prompt);
  letter-spacing: 0;
  text-transform: uppercase;
}

.dash-location-bold {
  font-family: var(--ccs-font-bold);
  font-size: 26px;
  color: var(--ccs-supporting-dark-grey);
  letter-spacing: 0;
}

.zone-health-number {
  font-family: var(--ccs-font-bold);
  font-size: 18px;
  letter-spacing: 0.27px;
}

.observation-audio-style {
  outline: none !important;
}

.text-message-wrap {
  white-space: normal;
}

.mark {
  background: orange;
  color: black;
}

.highlight {
  color: var(--ccs-risk-orange);
  font-family: var(--ccs-font-semibold);
  font-size: 16px;
}

.mark-bold {
  color: black;
  font-family: var(--ccs-font-semibold);
  font-size: 16px;
}
