.custom-carousel {
  width: 100%;
  padding-top: 45px;
  @include media("screen", "<tablet") {
    padding: 20px 0;
  }

  .ngucarousel {
    height: auto !important;
  }

  .item {
    padding: 0;
  }

  .tile {
    box-shadow: none !important;

    &-image {
      height: 400px;
      display: flex;
      display: -ms-flexbox;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      @include media("screen", "<tablet") {
        height: 280px;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        pointer-events: none;
      }
    }

    &-content {
      text-align: center;
      margin-top: 30px;
      @include media("screen", "<tablet") {
        margin-top: 15px;
      }

      h3 {
        margin: 0 0 10px;
        padding: 0;
        color: var(--ccs-base-dark-blue);
        line-height: normal;
        font-size: 24px;
        font-family: var(--ccs-font-bold);
      }

      p {
        margin: 0;
        padding: 0;
        font-size: 15px;
        line-height: 1.33;
        color: var(--ccs-supporting-dark-grey);
      }
    }
  }
}
