/**
* Conditional Media Query Mixin
*/

$width-desktop: 1280px;
$width-tablet: 768px;
$width-phone: 568px;
@function translate-media-condition($c) {
  $condMap: ("screen": "only screen", "print": "only print", "retina": "(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)", ">desktop": "(min-width: #{$width-desktop + 1})", "<desktop": "(max-width: #{$width-desktop})", ">tablet": "(min-width: #{$width-tablet + 1})", "<tablet": "(max-width: #{$width-tablet})", ">mobile": "(min-width: #{$width-phone + 1})", "<phone": "(max-width: #{$width-phone})");
  @return map-get($condMap, $c);
}

// The media mixin
@mixin media($args...) {
  $query: "";
  @each $arg in $args {
    $op: "";
    @if ($query !="") {
      $op: " and ";
    }
    $query: $query+$op+translate-media-condition($arg);
  }
  @media #{$query} {
    @content;
  }
}


/**
 * Usage examples

  @include media( "screen", ">tablet", "<desktop") {
    background: red;
    color: white;
  }
  ;
  @include media( "screen", ">mobile", "<tablet") {
    background: green;
    color: white;
  }
  ;
  @include media( "screen", "<phone") {
    background: blue;
    color: white;
  }
  ;
}


 */
