@import "./../core";

/*

Styles for "Observations" pages:
1.
\src\app\modules\observation\pages\condition-detail\condition-detail.page.html

2.
\src\app\modules\observation\pages\quality-detail\quality-detail.page.html

3.
\src\app\modules\observation\pages\coaching-detail\coaching-detail.page.html

4.
\src\app\modules\observation\pages\compliment-detail\compliment-detail.page.html

5.
\src\app\modules\observation\pages\ca-detail\ca-detail.page.html

6.
\src\app\modules\observation\pages\process-detail\process-detail.page.html

*/

.detail-page-section {
  display: block;
  margin-top: 25px;

  .hr-dashed {
    margin-top: 25px;
  }

  .headline-medium {
    margin-bottom: 25px;

    & + image-gallery-modal {
      display: block;
      margin-top: -20px;
    }
  }

  .headline-medium.headline-medium-with-button {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    @include media("screen", "<phone") {
      flex-wrap: wrap;
    }

    .page-button {
      margin-left: 20px;
    }
  }

  image-gallery-modal {
    margin-bottom: 20px;
  }

  .section-grid {
    display: flex;
    flex-wrap: wrap;

    .section-grid-left {
      width: 45%;
      margin-right: 10%;
    }

    .section-grid-right {
      width: 45%;
    }

    .section-grid-footer {
      margin: 20px 0;
    }
  }

  .observation-notes-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media("screen", "<phone") {
      flex-wrap: wrap;
    }

    .page-button {
      margin: 0;
    }
  }

  .observation-notes-subhead {
    font-family: var(--ccs-font-bold);
    font-size: 18px;
    margin-right: 10px;

    &.subhead-open {
      color: var(--ccs-risk-yellow);
    }

    &.subhead-quality {
      color: var(--ccs-quality-purple);
    }

    &.subhead-fixed {
      color: var(--ccs-risk-green);
    }

    &.subhead-logged {
      color: var(--ccs-risk-red);
    }
  }

  .notes-list {
    list-style-type: none;
    padding: 0;

    li {
      display: flex;
      margin-right: 15px;
      @include media("screen", "<phone") {
        flex-wrap: wrap;
      }
    }

    &.notes-list-audio {
      margin-left: 40px;
    }

    .notes-list-user-img {
      float: left;

      .user-img {
        margin-top: 5px;
      }
    }

    .notes-list-item-text {
      margin-top: -5px;
    }
  }

  .observation-audio, .note-audio {
    max-width: 490px;
  }

  .header-table td {
    vertical-align: top;
  }

  .table-header-text {
    font-family: var(--ccs-font-medium) !important;
    color: var(--ccs-color-form-prompt) !important;
    font-size: 12px !important;
    line-height: 14px !important;
    letter-spacing: 0;
    text-align: left;
  }

  /* Slider without Input Number */
  .full-width-slider input {
    display: none;
  }

  .full-width-slider .ui-slider-track {
    background: url(/assets/icons/sliderColor.svg);
    margin-left: 15px;
    outline: none !important;
  }

  .grid-box-text {
    font-family: var(--ccs-font-semibold);
    font-size: 16px;
    color: var(--ccs-supporting-dark-grey);
    letter-spacing: 0;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .text-box-big-black {
    font-family: var(--ccs-font-bold);
    font-size: 50px;
    color: var(--ccs-supporting-dark-grey);
    letter-spacing: -0.67px;
    text-align: center;
  }

  .align-number {
    position: relative;
    padding: .4em 1em;
    overflow: hidden;
    display: block;
    clear: both;
  }

  .user-img {
    @include avatar-medium;
    margin-right: 25px;
  }

  .grid-box-underline {
    font-family: var(--ccs-font-semibold);
    font-size: 20px;
    color: var(--ccs-base-blue);
    text-decoration: underline;
    margin-top: 10px;
    margin-left: 40%;
    line-height: normal;
    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .col-border-right {
    border-right: 1.5px dashed rgba(201, 201, 201, 0.3) !important;
  }

  .col-border-left {
    border-left: 1.5px dashed rgba(201, 201, 201, 0.3) !important;
  }

  .green-block {
    min-height: 40px;
    min-width: 100px;
    padding-top: 10px;
    text-align: center;
    background-color: var(--ccs-risk-green);
    border-radius: var(--border-radius);
    font-family: (--ccs-font-semibold);
    font-family: var(--ccs-font-semibold);
    font-size: 16px;
    color: var(--ccs-base-white);
    letter-spacing: 0;
  }

  .red-block {
    min-height: 40px;
    min-width: 100px;
    padding-top: 10px;
    text-align: center;
    color: white;
    background-color: var(--ccs-risk-red);
    border-radius: var(--border-radius);
    font-family: (--ccs-font-semibold);
    font-family: var(--ccs-font-semibold);
    font-size: 16px;
    color: var(--ccs-base-white);
    letter-spacing: 0;
  }

  .detail {
    background-color: var(--ccs-base-white);
    position: relative;
  }

  .image-spacer {
    margin-left: 5px;
    margin-right: 5px;
  }

  .adjustment-grid {
    background: var(--ccs-supporting-light-grey3);
    border: 1px solid var(--ccs-supporting-border-grey);

    .adjustment-grid-item-wrap {
      margin-bottom: 20px;
    }

    .adjustment-grid-inline {
      display: flex;
    }

    .adjustment-grid-inline-item {
      width: 31%;
      margin: 15px 2% 0 0;
    }

    .adjustment-grid-item {
      clear: both;
      margin-top: 10px;
    }

    .adjustment-grid-value {
      font-family: var(--ccs-font-semibold);
      font-size: 20px;
      color: var(--ccs-supporting-dark-grey);
    }

    .adjustment-icon-tick {
      margin-right: 10px;
    }

    .adjustment-grid-ion-grid {
      --ion-grid-padding: 40px;
      @include on-ie-11 {
        padding: 40px;
      }

      ion-row {
        margin: 0 -40px;

        ion-col {
          --ion-grid-column-padding: 0;
          padding: 0 40px;

          .adjustment-grid-item-wrap:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .block-range {
    padding: 30px 25px;
    background: var(--ccs-supporting-light-grey3);
    border: 1px solid var(--ccs-supporting-border-grey);

    .block-range-header {
      display: flex;
      align-items: center;

      .block-range-title {
        font-family: var(--ccs-font-bold);
        font-size: 18px;
        color: var(--ccs-base-dark-blue);
        letter-spacing: 0;
        display: flex;
        margin-right: auto;
      }

      .block-range-text {
        font-size: 14px;
        font-family: var(--ccs-font-bold);
        color: rgb(199, 199, 199);
        letter-spacing: 0;
      }
    }

    .block-range-slider {
      text-align: center;
      width: 100%;
      margin: 25px auto 28px;

      .block-range-subtitle {
        font-family: var(--ccs-font-bold);
        font-size: 18px;
        color: var(--ccs-base-dark-blue);
        text-align: center;
        margin: 0 0 25px;
      }

      .range-labels {
        display: flex;
        justify-content: space-between;
        margin: 10px 0 0;

        .range-label {
          font-family: var(--ccs-font-regular);
          color: var(--ccs-base-blue);
          font-size: 14px;
        }
      }
    }

    .slider {
      width: 93%;
      margin: 0 auto;
      display: block;
      -webkit-appearance: none;
      outline: none;
      -webkit-transition: .2s;
      transition: opacity .2s;
      height: 10px;
      background: var(--ccs-supporting-light-grey2);
      border: 1px solid var(--ccs-supporting-border-grey);
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, .15);
      border-radius: var(--border-radius);
      background-image: linear-gradient(90deg, #279F3E 0%, #FAD400 51%, #D0021B 100%);
    }

    /* Special styling for WebKit/Blink */
    input[type=range]::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 34px;
      width: 34px;
      cursor: pointer;
      background: var(--ccs-supporting-light-grey3);
      border: 1px solid var(--ccs-supporting-border-grey);
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .20);
      border-radius: var(--border-radius-checkbox);
    }

    /* All the same stuff for Firefox */
    input[type=range]::-moz-range-thumb {
      box-shadow: 1px 1px 1px #c4d8eb, 0 0 1px var(--ccs-supporting-blue-highlight);
      border: 1px solid #c5e8f3;
      height: 34px;
      width: 34px;
      border-radius: var(--border-radius-checkbox);
      background: var(--ccs-base-white);
      cursor: pointer;
    }

    // IE11 fixes for input[type=range]
    input[type=range] {
      padding: 0;
    }

    input[type=range]::-ms-track {
      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    input[type=range]::-ms-thumb {
      box-shadow: 1px 1px 1px #c4d8eb, 0 0 1px var(--ccs-supporting-blue-highlight);
      border: 1px solid #c5e8f3;
      width: 34px;
      background: var(--ccs-base-white);
    }

    input[type=range]::-ms-fill-lower {
      background: transparent;
    }

    input[type=range]::-ms-fill-upper {
      background: transparent;
    }

    input[type=range]::-ms-ticks-before {
      background: transparent;
    }

    input[type=range]::-ms-ticks-after {
      background: transparent;
    }
  }
}

@include media("screen", "<phone") {
  .detail-page-section .section-grid {
    .section-grid-left,
    .section-grid-right {
      width: 100%;
      margin: 0 0 20px 0;
    }
  }
  .detail-page-section .adjustment-grid .adjustment-grid-inline-item {
    width: 100%;
  }
  .detail-page-section .block-range {
    padding: 10px;
  }
  .detail-page-section .block-range .block-range-header {
    flex-wrap: wrap;
  }
  .detail-page-section .block-range .block-range-header .block-range-title {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .detail-page-section .user-img {
    margin-right: 10px;
  }
  .detail-page-section .notes-list li {
    margin-right: 0;
  }
  .notes-list-item-content .body-copy-bold {
    width: 100%;
    display: block;
  }
}

.observation-circle {
  &:before {
    content: "";
    background: var(--ccs-base-white);
    border-radius: 50%;
    vertical-align: middle;
    width: 15px;
    height: 15px;
    display: inline-block;
  }

  &.red {
    &:before {
      background: var(--ccs-risk-red);
    }
  }

  &.yellow {
    &:before {
      background: var(--ccs-risk-yellow);
    }
  }

  &.green {
    &:before {
      background: var(--ccs-risk-green);
    }
  }
}
