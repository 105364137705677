ion-popover {
  background: rgba(21, 59, 79, .8);

  &::part(content) {
    width: auto !important;
    border: 1px solid var(--ccs-color-border);
    box-shadow: 0 0 12px rgba(0, 0, 0, .6) !important;
    position: relative !important;
    top: auto !important;
    left: auto !important;
    overflow: visible !important;
    --min-height: 150px;
    @include on-ie-11 {
      .body {
        overflow: auto;
      }
    }

    .item-native {
      min-height: 69px !important;
      padding: 0 !important;
      @include on-ie-11 {
        padding: 0 0 0 15px !important;
        background: transparent;
        border-color: var(--ccs-table-border);
      }
    }
  }

  .header {
    padding: 20px 15px;

    .popup-close-button {
      top: -18px;
      right: -18px;
      position: absolute;
      cursor: pointer;
      background: url(/assets/icons/close_x_black.svg) no-repeat center;
      background-color: var(--ccs-base-blue);
      height: 36px;
      width: 36px;
      background-size: 14px;
      cursor: pointer;
      border-radius: 50%;
      transition: all 0.25s ease;

      &:hover {
        opacity: 0.95;
      }
    }

    .title {
      font-size: 20px;
      text-align: center;
      display: block;
      padding: 0;
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: var(--ccs-base-dark-blue);
      font-family: var(--ccs-font-bold);
      letter-spacing: 0;
    }
  }

  .body {
    padding: 0 15px 15px;
    min-width: 340px;
    max-height: 70vh;
    min-height: 70px;
    overflow: auto;

    input[type="text"] {
      width: 100%;
    }
  }

  .button-block {
    padding: 0 15px;
    display: flex;
    justify-content: space-between;

    &.center-one-btn {
      justify-content: center;
    }

    .page-button {
      margin-top: 0;
    }
  }

  ion-backdrop {
    opacity: 0 !important;
  }
}

@include media("screen", "<tablet") {
  ion-popover .popover-content {
    max-width: 90vw !important;
    max-height: 100% !important;
    min-height: 200px !important;
  }
  ion-popover .body {
    width: 100% !important;
    height: auto !important;
    max-height: 60vh !important;
  }
}

@include media("screen", "<phone") {
  ion-popover .popover-content {
    max-width: 90vw !important;
  }
  ion-popover .body {
    min-width: 280px;
  }
}
