.arrow {
  @include arrow;

  &.right {
    @include right-arrow-direction;
  }

  &.left {
    @include left-arrow-direction;
  }

  &.up {
    @include up-arrow-direction;
  }

  &.down {
    @include down-arrow-direction;
  }
}
