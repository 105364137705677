.table-modal-link {
  text-decoration: underline;
  font-family: var(--ccs-font-bold);
  font-size: 15px;
  color: var(--ccs-base-blue);
}

.row-link {
  text-decoration: underline;
  font-family: var(--ccs-font-bold);
  font-size: 15px;
  color: var(--ccs-base-blue);
  user-select: none;
  white-space: nowrap;
}

td > ul {
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-inline-start: 15px;
}

table {
  .btn.btn-link {
    background-color: transparent !important;
    color: var(--ccs-base-blue) !important;
    border: none !important;
    text-decoration: underline;
    font-size: 15px !important;
  }
}

.table-container {
  .group-search {
    position: absolute;
    top: 40px;
    right: 40px;
    margin: 0;
  }

  .table-row-image {
    width: 50px;
    height: 50px;
  }
}

.badge-label {
  border-radius: 4px;
  color: var(--ccs-base-white);
  font-family: var(--ccs-font-extrabold);
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 12px;
  padding: 5px 10px;
  text-transform: uppercase;
  white-space: nowrap;

  &.red {
    background-color: var(--ccs-risk-red);
  }

  &.yellow {
    background-color: var(--ccs-color-warning);
    color: var(--ccs-table-text);
  }

  &.green {
    background-color: var(--ccs-risk-green);
  }
}

.folder-path {
  font-size: 11px;
  color: var(--ccs-supporting-medium-grey);
}

.table-top-search-box {
  .dataTables_filter {
    position: fixed;
    right: 40px;
    top: 40px;
    @include on-ie-11 {
      top: 115px;
    }
    @include media("screen", "<tablet") {
      position: relative;
      right: auto;
      top: auto;
    }
  }
}
