ion-footer {
  opacity: .95;
  height: var(--footer-height);

  ion-toolbar {
    --padding-bottom: 0;
    --padding-end: 0;
    --padding-start: 0;
    --padding-top: 0;
  }
}

// padding for ion-content for IE11
@include on-ie-11 {
  .inner-scroll {
    padding: var(--page-padding) !important;
  }
  .ion-page:not(app-layout):not(app-loading):not(app-login) .ion-page:not(app-layout):not(app-loading):not(app-login) .inner-scroll > *:last-child {
    padding-bottom: 0px;
    // display: flex;
  }
  .modal-wrapper .inner-scroll {
    padding: 0 0 40px !important;
  }
  app-login .inner-scroll {
    padding: 0 !important;
  }
}

ion-header {
  box-shadow: none !important;

  &.modal-page {
    display: flex;
    justify-content: flex-end;
    background: var(--ccs-base-white);

    .close-action {
      background: url('/assets/icons/close_x_black.svg') no-repeat center;
      background-color: var(--ccs-base-blue);
      height: 75px;
      width: 75px;
      background-size: 30px;
      cursor: pointer;
    }
  }

  &::after {
    display: none;
  }
}

app-layout > ion-content {
  height: calc(100% - 75px) !important;
  @include on-ie-11 {
    height: 100% !important;
  }
}

ion-content {
  --padding-start: var(--page-padding) !important;
  --padding-end: var(--page-padding) !important;
  --padding-top: var(--page-padding) !important;
  --padding-bottom: var(--page-padding) !important;

  &.table-content {
    --padding-end: var(--page-padding) !important;
    --padding-start: var(--page-padding) !important;
    --padding-top: var(--page-padding) !important;
  }

  .page-title-old {
    font-family: var(--ccs-font-bold);
    color: #1F3B4F;
    font-size: 1.5em;
  }
}

@include media("screen", "<desktop") {
  ion-content {
    --padding-end: var(--page-padding) !important;
    --padding-start: var(--page-padding) !important;
    --padding-end: var(--page-padding) !important;
    --padding-start: var(--page-padding) !important;

    .sc-ion-content-1 {
      margin-left: 10px !important;
      margin-right: 10px !important;
    }
  }
}

@include media("screen", "<tablet") {
  ion-content {
    --padding-end: 15px !important;
    --padding-start: 15px !important;
  }
}

.table-content {
  overflow: auto;

  .page-button.table-header-button {
    position: inherit;
    margin-top: 0;
    z-index: 1;
    margin-bottom: 20px;
    margin-right: 15px;
  }
}

.tabs-wrap {
  margin-bottom: 30px;
}

.dt-buttons {
  .dt-button {
    margin-bottom: 0;
  }
}

// .ion-page ion-header+ion-content {
//   position: absolute;
//   height: calc(100% - 75px);
//   top: 75px;
// }
.avg-location {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
  font-family: var(--ccs-font-bold);
  color: var(--ccs-table-text);
}

ion-icon.custom_close_icon {
  position: absolute;
  top: 13px;
  right: 8px;
  cursor: pointer;
}

ion-content::part(scroll) {
  padding-top: 0px;
}

.medium, .wide {
  > .page-title {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  > p, .title-block > p {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 10px;
    color: var(--ccs-supporting-dark-grey);
  }
  .table-title {
    margin-top: 10px !important;
    margin-bottom: 5px !important;
    &.headline-small {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
}

.title-block {
  margin-top: 40px;
  margin-bottom: 30px;
}

.cloud-over {
  margin-left: 15%;
  margin-top: 10px important;
  cursor: pointer !important;
  transition: all .2s ease-in-out !important;
}

.cloud-over:hover {
  cursor: pointer !important;
  transform: scale(1.1) !important;
}


@media only screen and (min-width: 1920px) {
  .cloud-over {
    margin-left: 30%;
  }
}


@media only screen and (min-width: 2560px) {
  .cloud-over {
    margin-left: 35%;
  }
}

[hidden] {
  display: none !important;
}
