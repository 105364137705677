.display-none {
  display: none;
}

.display-block {
  display: block;
}

.clearfix {
  clear: both;
}

*:disabled,
.disabled {
  opacity: .3;
  pointer-events: none;
}

.dotted-border-right {
  border-right: 1.55px dashed rgba(201, 201, 201, 0.5);
}

.hr-dashed {
  margin-top: 0;
  margin-bottom: 0;
  height: 1px;
  background-image: linear-gradient(90deg, #E6E6E6, #E6E6E6 75%, transparent 75%, transparent 100%);
  background-size: 10px 1px;
  border: none;
}

.red-border {
  border: 3.0px solid var(--ccs-risk-red) !important;
  border-radius: 8px;
}

.yellow-border {
  border: 3.0px solid var(--ccs-risk-yellow) !important;
  border-radius: 8px;
}

.purple-border {
  border: 3.0px solid var(--ccs-quality-purple) !important;
  border-radius: 8px;
}

.green-border {
  border: 3.0px solid var(--ccs-risk-green) !important;
  border-radius: 8px;
}

.opportunity-border {
  border: 3.0px solid var(--ccs-opportunity-color) !important;
  border-radius: 8px;
}

.azure-border {
  border: 3.0px solid var(--css-asset-azure) !important;
  border-radius: 8px;
}

.narrow {
  max-width: 500px;
  margin: 0 auto;
}

.medium {
  max-width: 700px;
  margin: 0 auto;
}

.wide {
  max-width: 1100px;
  margin: 0 auto;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.image-spacer {
  margin-left: 3px;
  margin-right: 3px;
}

.top-spacer {
  margin-top: 20px;
  margin-bottom: 20px;
}

.obs-dot {
  width: 15px !important;
  height: 15px !important;
}

.wo-small {
  background: var(--ccs-base-dark-blue);
  border-radius: var(--border-radius);
  font-family: var(--ccs-font-bold);
  font-size: 10px;
  color: var(--ccs-base-white);
  letter-spacing: 0;
  text-align: center;
  width: 30px !important;
  height: 20px !important;
  line-height: 20px;
}

.wo-small-spacing {
  float: right;
}

.table-text-wrap {
  white-space: normal;
  width: 350px !important;
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.custom-close-icon {
  .close-action {
    right: 0;
  }
}

.custom-col-vis {
  position: fixed !important;
  top: 35% !important;
  left: 0;
  right: 0;
  margin: 0 auto !important;
}
