::ng-deep {
  ion-menu {
    ion-backdrop {
      opacity: 0 !important;
    }
  }
}

.menu-content-open {
  pointer-events: all;
}

ion-menu {
  --ion-backdrop-color: transparent;
  --width: 325px;

  ion-toolbar {
    --padding-top: 0;
    --padding-start: 0;
    --padding-right: 0;
    --padding-end: 0;
    -ms-overflow-style: none;
    height: 100vh;
    overflow: auto;

    .menu-content {
      .menu-list {
        padding: 0;
      }
    }
  }
}

::ng-deep {
  .menu-inner {
    width: 350px !important;
    @include media("screen", "<tablet") {
      width: 300px !important;
    }
  }
}
